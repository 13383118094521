import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import Button from '@interness/web-core/src/components/elements/Button/Button';
import Link from '@interness/web-core/src/components/elements/Link/Link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Berufsordnung für Tierheilpraktiker`}</h1>
      <p>{`  Die Berufsordnung für Tierheilpraktiker ist im Mitgliederbereich einzusehen`}</p>
  <div style={{
        textAlign: 'center'
      }}>
    <Button as={Link} to='/mitgliederbereich/berufsordnung-adt' mdxType="Button">Berufsordnung</Button>
  </div>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      